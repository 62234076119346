<template>
  <v-btn class="pa-7 rounded-lg" :color="fbBlue" dark @click="loginWithFacebook"> 
    <v-icon class="mr-2">mdi-facebook</v-icon>
    Sign in with facebook
  </v-btn>
</template>

<script>
import colorMxn from '@/mixins/color_mixin';
import { initFbsdk } from '@/config/fb.js'
import { USER_FIELDS } from '@/queries/account';
import gql from 'graphql-tag';

export default {
  name:"facebookLogin",
  mixins: [colorMxn],
  mounted() {
    initFbsdk()
  },
  methods: {
    loginWithFacebook () {
      window.FB.login(response => {
        this.post(response);
      }, this.params)
    },
    post(response) {
      let payload = {
        socialAuthAccessToken: response.authResponse.accessToken,
        socialAuthProvider: 'facebook',
      };

      const POST_AUTH= gql`mutation($socialAuthAccessToken: String!, $socialAuthProvider: String!) {
        socialAuth(accessToken: $socialAuthAccessToken, provider: $socialAuthProvider) {
          social {
            provider
            uid
            extraData
            created
            modified
            user {
              ${USER_FIELDS}
            },
          }
          token
          refreshToken
        }
      }`;

      this.$apollo.mutate({
        mutation: POST_AUTH,
        variables: payload,
        update: (cache, {data, errors}) => {
          this.$store.state.login_success = true;
          let user = data.socialAuth.social.user;
          this.$store.state.user = user
          let dt = {
            "token": data.socialAuth.token,
            "refresh_token": data.socialAuth.refreshToken,
            "push": true,
          };
          this.$store.commit("completeLogin", dt)
        },
      }) 
    
    }

  }
};
</script>
